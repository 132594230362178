

import {debounce, throttle, setActive} from './functions';
import '../../../config/node_modules/bootstrap/js/dist/collapse';
var $ = require("../../../config/node_modules/jquery");

async function ifEditing() {
    $(document).ready( function () {
        if(parent.document.getElementById('config-bar')) {
            $('body').addClass('config-mode');
            barba.destroy();
            setTimeout(() => {
                scrollCont.destroy();
            }, 100);
            
        }
    });
}

function slidetoggle(el) {
    
    const ch = el.clientHeight,
    sh = el.scrollHeight,
    isCollapsed = !ch,
    noHeightSet = !el.style.height;

    console.log({isCollapsed}, {noHeightSet}, {sh})

    if (isCollapsed){
        el.previousElementSibling.classList.add("active");
    } else {
        el.previousElementSibling.classList.remove("active");
    }
    el.style.height = (isCollapsed || noHeightSet ? sh : 0) + "px";
    if (noHeightSet) return slidetoggle.call(this);
    
}

async function accordionClick() {
    var acc = document.getElementsByClassName("accordion");
    var i;
    
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function() {
        
       
        var panel = this.nextElementSibling;
        [].forEach.call(
            document.querySelectorAll('.panel'),
            el => {
                // console.log(panel, el)
                el.style.height = '0px';

                if (el.previousElementSibling.classList.contains('active')){
                    el.previousElementSibling.classList.remove("active");
                }
               
            });
        slidetoggle(panel);
      });
    }
}


async function formLabels() {
[].forEach.call(
    document.querySelectorAll('.form-field__label, .form-field__input, .form-field__select, .form-field__textarea, .form-field__file'),
    el => {
        el.onblur = () => {
            setActive(el, false);
        };
        el.onfocus = () => {
            setActive(el, true);
        };
    });
}
async function copyLink() {
    $('.copy-link').on('click', function(e) {
        e.preventDefault();
        var href = $(this).attr('href');
        copyToClipboard(href);
    });

    const copyToClipboard = (href) => {
        var $temp = $("<input>");
        $("body").append($temp);
        $temp.val(href).select();
        document.execCommand("copy");
        $temp.remove();
    }
}


export {
    ifEditing, formLabels, copyLink, accordionClick
}
            var menu = $(".navbar_nav");
            if ($("li.main-nav.active")[0]){
                var indicator = $('<div class="indicator"></div>');
                menu.append(indicator);
                position_indicator(menu.find("li.main-nav.active"));  
                menu.find("li.main-nav").mouseenter(function(){
                    position_indicator($(this));
                });
                
                menu.find("li.main-nav").mouseleave(function(){
                    position_indicator(menu.find("li.main-nav.active"));
                });
                
                function position_indicator(ele){
                    var conleft = $('.navbar_collapse').offset().left;
                    var left = ele.offset().left;
                    var width = ele.width();
                    var widthhalf =  width / 2;
                    var centerLeft = conleft - left - widthhalf;
                    indicator.stop().animate({
                        left: Math.abs(centerLeft),
                        width: 5
                    });
                }
            }
