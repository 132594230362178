// import { gsap } from '../../../config/node_modules/gsap';
// import { ScrollTrigger } from "../../../config/node_modules/gsap/ScrollTrigger";
// gsap.registerPlugin(ScrollTrigger);



// var rotate = gsap.timeline({
//     scrollTrigger:{
//       trigger: "#scroll-trigger",
//       pin: true,
//       scrub:0.2,
//       start: 'top top',
//       end:'+=100',
//     }
//   })
//   .to('#scroller', {
//     rotation: 360*5,
//     duration:1, ease:'none',
//   })

window.onscroll = function() {
    var theta = document.documentElement.scrollTop / 250 % Math.PI;

    console.log(theta)
    document.getElementById('scroll-trigger').style.transform ='rotate(' + theta + 'rad)';
}
