import '../../config/node_modules/regenerator-runtime/runtime';
import './scripts/functions';
import './scripts/lazyload';
import {accordionClick} from './scripts/general';
accordionClick();
import './scripts/updatecss';
import {imageSlider} from './scripts/swiper';
imageSlider();
import {menu} from './scripts/menu';
menu();
import './scripts/scroller';
import './styles/style';