


import { throttle } from './functions';
import gsap from '../../../config/node_modules/gsap';
var $ = require("../../../config/node_modules/jquery");

async function menu() {
    var clicks = !0,
    navbarToggler = document.querySelector(".navbar-toggler");
    const mediaQuery = window.matchMedia('(max-width: 1024px)')

    if (navbarToggler && mediaQuery.matches) {

        function runMenu(e) {
            !0 === e ? menuTL.timeScale(1).play() : menuTL.timeScale(2).reverse()
        }

        navbarToggler.addEventListener("click", throttle(function(e) {
            var t = document.querySelector(".navigation"),
                o = document.querySelector("body");
            (clicks = !clicks) ? (o.classList.remove("lock-scroll"), runMenu(!1), t.classList.remove("menu-open")) : (o.classList.add("lock-scroll"), runMenu(!0), t.classList.add("menu-open"))
        }, 1000));

        var menuTL = gsap.timeline({paused: true}),
        menuParent = document.querySelector("#navbarResponsive"),
        MainMenuItems = document.querySelectorAll(".navigation .navbar-nav .nav-item"),
        MainMenuItemsInner = document.querySelectorAll(".navigation .navbar-nav .nav-item .nav-link");
    
        menuTL.set(MainMenuItemsInner, {autoAlpha: 0, y: 100});

        menuTL.to(menuParent, {
            autoAlpha: 1,
        }),
        menuTL.to(MainMenuItemsInner, {
            duration: .4,
            autoAlpha: 1,
            stagger: .1,
            y: 0,
            ease: "Power1.easeOut",
        });
    }
        
}
export {
    menu
}