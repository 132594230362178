// THROTTLE FUNCTION
export const throttle = function (func, limit) {
    let lastFunc;
    let lastRan;
    return function () {
        const context = this;
        const args = arguments;
        if (!lastRan) {
            func.apply(context, args);
            lastRan = Date.now();
        } else {
            clearTimeout(lastFunc);
            lastFunc = setTimeout(function () {
                if (Date.now() - lastRan >= limit) {
                    func.apply(context, args);
                    lastRan = Date.now();
                }
            }, limit - (Date.now() - lastRan));
        }
    };
};

// DEBOUNCE FUNCTION
export const debounce = function (func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export const loadScript = function(container, url, callback) {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;

    if(callback !== false){
        script.onreadystatechange = callback;
        script.onload = callback;
    }
    
    // Fire the loading
    container.appendChild(script);
}

export const setActive = (el, active) => {
    const formField = el.parentNode;
    if (active) {
        formField.classList.add('form-field--is-active');
    } else {
        formField.classList.remove('form-field--is-active');
        el.value === '' ?
            formField.classList.remove('form-field--is-filled') :
            formField.classList.add('form-field--is-filled');
    }
};
